import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {from, Observable, throwError} from 'rxjs';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {catchError, switchMap} from "rxjs/operators";
import { CaptchaService } from './common/captchaService.service';

@Injectable({
  providedIn: 'root'
})
export class Interceptor implements HttpInterceptor {

  constructor(private captchaService:CaptchaService) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // add JWT auth header if a user is logged in for API requests
    const accessToken = localStorage.getItem('token');
    //const isApiUrl = request.url.startsWith(environment.base_url);
    const timestamp = new Date().toISOString();
    if (accessToken ) {
      return from(this.captchaService.encryptData(timestamp)).pipe(
          switchMap((encryptedTimestamp) => {
            request = request.clone({
              headers: new HttpHeaders({
                Authorization: 'Bearer ' + accessToken,
                'X-hguyt-sdfds': encryptedTimestamp
              }),
            });

            if (window.location.origin.includes('localhost')) {
              request = this.reRoute(request);
            }

            return next.handle(request).pipe(
                catchError((err: HttpErrorResponse) => {
                  this.handleServerSideError(err);
                  return throwError(err);
                })
            );
          })
      );
    }
  // request = this.handleSpecialCharURL(request);
    if(window.location.origin.includes('localhost')) {
      request = this.reRoute(request);
    }
    return next.handle(this.handleSpecialCharURL(request));
  }

  private handleSpecialCharURL(request:HttpRequest<any>) {
    let url = request.url;
    let pattern = url.split("?");
    if(pattern.length > 0 && pattern[1] !== null && pattern[1] !== undefined) {
      let splitPart = pattern[1].split("&");
      let newSplit = [];
      splitPart.forEach((i,index) => {
        if(!i.includes("=") && index > 0) {
          newSplit[newSplit.length-1] += "&"+ i;
        } else {
          newSplit[newSplit.length] = i;
        }
      })
      let url = pattern[0]+"?";
      newSplit.forEach((splitPart,index) => {
        let spl = splitPart.split("=");
        url += ((index != 0)?"&":"") +spl[0]+"="+ encodeURIComponent(spl[1]);
      })
      return request.clone({'url': url});
    }
    return request;
  }


  private reRoute(request:HttpRequest<any>):HttpRequest<any> {
    let route = request.url;
    if(route.includes("//localhost/")) {
      let json:{[key:string]:any} = {
        'ctrm-api':'http://localhost:8080',
        'api-iam':'https://client-uat-3.ctrm-xceler.com',
        'api-bm':'https://client-uat-3.ctrm-xceler.com',
        'integration-service':'https://client-uat-3.ctrm-xceler.com',
        'approval-workflow':'https://client-uat-3.ctrm-xceler.com'
      }
      let url = route.replace("http://localhost","");
      let split = url.split("/");
      if(Object.keys(json).includes(split[1])) {
        route = json[split[1]]+url;
      } else {
        route = "http://localhost:8080"+url;
      }
    }
    return request.clone({'url': route})
  }
  private handleServerSideError(error: HttpErrorResponse) {
    if(error.status===401){
      localStorage.clear();
      window.location.href = '/auth/';
    }
    console.log(error);
  }
}
