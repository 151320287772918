export const environment = {
    production: false,
    base_url: 'https://client-uat-3.ctrm-xceler.com/ctrm-api',
    base_url_new: 'https://client-uat-3.ctrm-xceler.com',
    service_url: 'http://192.168.1.7:8502/ctrm/pdf-rpa',
    dateFormat: 'dd MMM yyyy',
    dateMonthFormat: 'MMM yyyy',
    dateYearFormat: 'yyyy',
    primaryKeyIncludedCharacters: ['-', ' ', '_'],
    full_date_format: 'dd MMM yyyy hh:mm:ss a',
    feedUrl: ['http://feeds.reuters.com/Reuters/worldNews'],
    publisherDateFormat: 'EEEE , dd MMM yyyy hh:mm:ss a',
    componentListType: ['L', 'B', 'OB', 'S', 'ML', 'C'],
    componentTextType: ['T', 'N', 'P', 'TA', 'LB', 'TN', 'SL'],
    importFileSize: 5000000,
    disabled_routes: [''],
    analytics_base_url: 'http://localhost:8501' // link of analytics
    //analytics_base_url: 'https://api-becrux-dev.ctrm-xceler.com/ui-dashboard/'
  };
