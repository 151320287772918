import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {CommonService} from "../../services/common.service";
import {environment} from "../../../environments/environment";
import {Grid} from "../../grid/grid.model";
import {BreadcrumbService} from "../ctrm-breadcrumb/breadcrumb.service";

@Component({

    selector: 'app-xceler-iframe',
    templateUrl: './xceler-iframe.component.html',
    styleUrls: ['./xceler-iframe.component.css']
})
export class XcelerIframeComponent implements OnInit {
    name = 'Set iframe source';
    @ViewChild('iframeObj', {static: true}) iframe: HTMLIFrameElement;
    url: string = environment.analytics_base_url;
    value:any ;
    urlSafe: SafeResourceUrl;
    @Input() showSectionTop: boolean = true;
    menuItems: any[] =[];
    completedURLs:any[]=['position',
        'pnl',
        'counterpartyReport',
        'shipment',
        'marketRisk',
        'counter-party',
        'revenue',
        'mpob',
        'MarketFuture',
        'MarketPhysical',
        'invoiceTracking',
        'dncnpaper',
        'arJournal',
        'purchaseMaster',
        'salesMaster',
        'marketsummary',
        'premiumContract',
        'additionalCost',
        'Fin',
        'prupload',
        'shipmentReport',
        'mtmReport',
        'vesselReport',
        'provisionalPriceReport',
        'staggeredPriceReport',
        'outstandingPurchaseTrade',
        'shippedAndLandedQty',
        'cargoReport',
        'allocationReport',
        'futureAndClosingReport',
        'idtReport',
        'futureDailyTradeReport',
        'paperTradeReport',
        'idtMirrorReport',
        'allocationStringReport',
        'benchmarkPhysicalTrackingReport',
        'SalesRealizationReport',
        'vesselStockReport',
        'additionalCostByVessel',
        'dischargeReport',
        'idtMatchingReport',
        'positionReport',
        'futureNettingReport',
        'bridgeContractReport',
        'OPSPositionReport',
        'CustomVesselPnLReport',
        'CommodityPriceReport',
        'CommodityUnPricedReport',
        'CustomerWiseReport',
        'CustomerUnPricedReport',
        'PositionCommodityReport',
        'DailyPriceSummary',
        'LblandGblSummary',
        'PaperBrokerageReport',
        'invoiceReport',
        'physicalPaperReport',
        'ValencyRiskReport',
        'ShipmentWithVesselReport',
        'ContractListingReport',
        'InvoiceListingReport',
        'ProductRiskDetailedReport',
        'PilPositionDashboard',
        
    ]

    policy:any;
    constructor(public sanitizer: DomSanitizer,
                public commonService:CommonService,
                public breadCrumbService: BreadcrumbService) { }

    ngOnInit() {

        this.policy=JSON.parse(localStorage.getItem('policy'));

        this.menuItems.push({header:'Trade Operation',key:'trop' ,isAccess:true
            ,childs:
                [
                    {header:'Purchase Master Report',key:'purchaseMaster',isAccess:this.isAccess("/purchaseMaster")},
                    {header:'Sales Master Report',key:'salesMaster',isAccess:this.isAccess("/salesMaster")},
                    {header:'MPOB Report',key:'mpob',isAccess:this.isAccess("/mpob")},
                    {header:'Outstanding Report',key:'outstandingPurchaseTrade',isAccess:this.isAccess("/outstandingPurchaseTrade")},
                    {header:'Contract Listing Report',key:'ContractListingReport',isAccess:this.isAccess("/ContractListingReport")},
                    {header:'Invoice Listing Report',key:'InvoiceListingReport',isAccess:this.isAccess("/InvoiceListingReport")},
                ]
        });
        this.menuItems.push({header:'Shipping Operations',key:'shop',isAccess:true
            ,childs:
                [
                    {header:'Shipment Schedule Report',key:'shipment',isAccess:this.isAccess("/shipment")},
                    {header:'Shipment Report',key:'shipmentReport',isAccess:this.isAccess("/shipmentReport")},
                    {header:'Shipped and Landed Qty',key:'shippedAndLandedQty',isAccess:this.isAccess("/shippedAndLandedQty")},
                    {header:'Cargo Report',key:'cargoReport',isAccess:this.isAccess("/cargoReport")},
                    {header:'Stock Report',key:'vesselStockReport',isAccess:this.isAccess("/vesselStockReport")},
                    {header:'Discharge Cargo Report',key:'dischargeReport',isAccess:this.isAccess("/dischargeReport")},
                    {header:'Long Cargo Report',key:'vesselReport',isAccess:this.isAccess("/vesselReport")},
                    {header:'Bridge Contract Report',key:'bridgeContractReport',isAccess:this.isAccess("/bridgeContractReport")},
                    {header:'Vessel PnL Report',key:'CustomVesselPnLReport',isAccess:this.isAccess("/CustomVesselPnLReport")},
                    {header:'Daily Trade by Commodity and Shipment Month Report',key:'physicalPaperReport',isAccess:this.isAccess("/physicalPaperReport")},
                    {header:'Shipment With Vessel Details Report',key:'ShipmentWithVesselReport',isAccess:this.isAccess("/ShipmentWithVesselReport")},
                ]
        });
        this.menuItems.push({header:'Risk Reports',key:'riskop',isAccess:true
            ,childs:
                [{header:'Trade Summary Report',key:'marketRisk' ,isAccess:true
                    ,childs:[{header:'Future-Paper Trade Report',key:'MarketFuture',isAccess:this.isAccess("/MarketFuture")},
                        {header:'Physical-IDT Trade Report',key:'MarketPhysical',isAccess:this.isAccess("/MarketPhysical")}]},
                    {header:'Future Daily Trade Report',key:'futureDailyTradeReport',isAccess:this.isAccess("/futureDailyTradeReport")},
                    {header:'Paper Trade Report',key:'paperTradeReport',isAccess:this.isAccess("/paperTradeReport")},
                    {header:'Open Position Report',key:'position',isAccess:this.isAccess("/position")},
                    {header:' Market Trade Summary Report',key:'marketsummary',isAccess:this.isAccess("/marketsummary")},
                    {header:'Benchmark Physical Tracking Report',key:'benchmarkPhysicalTrackingReport',isAccess:this.isAccess("/benchmarkPhysicalTrackingReport")},
                    {header:'Counterparty Risk Report',key:'counterpartyReport',isAccess:this.isAccess("/counterpartyReport")},
                    {header:'Provisional Price Report',key:'provisionalPriceReport',isAccess:this.isAccess("/provisionalPriceReport")},
                    {header:'Staggered Price Report',key:'staggeredPriceReport',isAccess:this.isAccess("/staggeredPriceReport")},
                    {header:'Allocation Report',key:'allocationReport',isAccess:this.isAccess("/allocationReport")},
                    {header:'Allocation String Report',key:'allocationStringReport',isAccess:this.isAccess("/allocationStringReport")},
                    {header:'Additional Cost By Vessel',key:'additionalCostByVessel',isAccess:this.isAccess("/additionalCostByVessel")},
                    {header:'Future And Closing Report',key:'futureAndClosingReport',isAccess:this.isAccess("/futureAndClosingReport")},
                    {header:'IDT Report',key:'idtReport',isAccess:this.isAccess("/idtReport")},
                    {header:'IDT Mirror Report',key:'idtMirrorReport',isAccess:this.isAccess("/idtMirrorReport")},
                    {header:'Sales Realization Report',key:'SalesRealizationReport',isAccess:this.isAccess("/SalesRealizationReport")},
                    {header:'IDT Matching Report',key:'idtMatchingReport',isAccess:this.isAccess("/idtMatchingReport")},
                    {header:'Position Report',key:'posRepo',isAccess:true
                        ,childs: [
                            {header: 'Position Dashboard',key:'PilPositionDashboard',isAccess:this.isAccess('/PilPositionDashboard')},
                            {header:'MTM Report',key:'MTM',isAccess:true ,
                            childs:[{header:'By Commodity',key:'PositionCommodityReport',isAccess:this.isAccess('/PositionCommodityReport'),},
                                {header:'By Month',key:'positionReport',isAccess:this.isAccess("/positionReport") ,},]},
                            {header:'Commodity Report',key:'commodityReport',isAccess:true ,
                                childs:[{header:'Commodity Price Report',key:'CommodityPriceReport',isAccess:this.isAccess("/CommodityPriceReport")},
                                    {header:'Commodity Unpriced Report',key:'CommodityUnPricedReport',isAccess: this.isAccess("/CommodityUnPricedReport")},]},
                            {header:'Customer Report',key:'customerReport',isAccess:true,
                                childs:[{header:'Customer Price Report',key:'CustomerWiseReport',isAccess:this.isAccess("/CustomerWiseReport")},
                                    {header:'Customer Unpriced Report',key:'CustomerUnPricedReport',isAccess:this.isAccess("/CustomerUnPricedReport")},]},
                            {header: 'Daily Price Summary',key:'DailyPriceSummary',isAccess:this.isAccess('/DailyPriceSummary')},],},
                    {header:'Future Netting Summary Report',key:'futureNettingReport',isAccess:this.isAccess("/futureNettingReport")},
                    {header:'Ops Position Report',key:'OPSPositionReport',isAccess:this.isAccess("/OPSPositionReport")},
                    {header:'Daily Long/Short MTM Report',key:'ValencyRiskReport',isAccess:this.isAccess("/ValencyRiskReport")},
                    {header:'Product-Wise Daily Long/Short MTM Report',key:'ProductRiskDetailedReport',isAccess:this.isAccess("/ProductRiskDetailedReport")},
                ]
        });
        this.menuItems.push({header:'Account & Finance',key:'finop',isAccess:true
            ,childs:
                [
                    {header:'PnL Report',key:'pnl',isAccess:this.isAccess("/pnl")},
                    {header:'MTM Report Extract',key:'mtmReport',isAccess:this.isAccess("/mtmReport")},
                    {header:'PR Upload Report',key:'prupload',isAccess:this.isAccess("/prupload")},
                    {header:'Finance Report',key:'Fin' ,isAccess:true
                        ,childs:[{header:'ARJournal Report',key:'arJournal',isAccess:this.isAccess("/arJournal")},
                            {header:'DNCN Paper Trade Report',key:'dncnpaper',isAccess:this.isAccess("/dncnpaper")},
                            {header:'Invoice Tracking Report',key:'invoiceTracking',isAccess:this.isAccess("/invoiceTracking")},
                            {header:'Additional Cost Report',key:'additionalCost',isAccess:this.isAccess("/additionalCost")},
                            {header:'Premium Contract Report',key:'premiumContract',isAccess:this.isAccess("/premiumContract")},
                            {header:'LBL and GBL Summary',key:'LblandGblSummary',isAccess:this.isAccess("LblandGblSummary")},
                            {header:'Paper Trade Brokerage Report',key:'PaperBrokerageReport',isAccess:this.isAccess("/PaperBrokerageReport")},
                            {header:'Invoice Report',key:'invoiceReport',isAccess:this.isAccess("/invoiceReport")},
                        ]
                    }
                ]
        });


        this.loadIframe("position");
        this.addEvent();

    }
    isAccess(key){
        if(this.policy !== null && this.policy !== undefined && Object.keys(this.policy).includes(key)) {
            const expression= this.policy[key]['Grid Access']['read'];
            return eval(expression)
        }
        return false;
    }

    loadIframe(value){
        const token= localStorage.getItem("token");
        this.value=value;


        if(value === null || value === undefined) {
            this.value ="position";
        }

        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url+"#/"+this.value);
        if(this.value=='mpob'){
            //this.urlSafe=
        }
    }

    broadcastChannel = new BroadcastChannel('user_inactivity_timer');
    addEvent(){
        setTimeout(()=>{
          document.getElementsByTagName('iframe')[0].contentDocument?.addEventListener('mousemove',()=> {
            this.broadcastChannel.postMessage({
                appicationName: "ctrm-event",
            });
          });
          document.getElementsByTagName('iframe')[0].contentDocument?.addEventListener('keyup',()=> {
            this.broadcastChannel.postMessage({
                appicationName: "ctrm-event",
              });
          })
        },20000);
    }
  

}

