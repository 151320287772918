import {Component, OnInit} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {MasterService} from '../../masters/services/MasterService';
import {Grid} from '../../grid/grid.model';
import {environment} from '../../../environments/environment';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Validators} from '@angular/forms';
import {ctrmValidators} from '../../services/validators/ctrmValidators';
import {KeyValue} from '../../grid/key-value.model';
import {defaultValue} from "../../grid/defaultValue.model";

@Component({
    selector: 'app-market-limits',
    templateUrl: './market-limits.component.html',
    styleUrls: ['./market-limits.component.css']
})
export class MarketLimitsComponent implements OnInit {

    grid: Grid;
    refreshGrid: boolean = false;
    onLoadData: any[] = [];
    columns: Map<string, Tcolumn>;
    quantityRoundOff:number;
    priceRoundOff:number;

    constructor(public commonService: CommonService, private masterCommonService: MasterService) {
    }

    ngOnInit(): void {
        this.commonService.getLoadData('commodity', ['config', 'config','config', 'data', 'data'],
            ['profitcenter', 'commodity','unitofmeasurement', [{key: 'name', value: 'trade_type_ind'}], [{key: 'name', value: 'notificaiton_type_ind'}]])
            .subscribe((next: any) => {
                this.onLoadData = next;
                this.getColumns();
                this.grid = new Grid(this.columns, true, true, true, true, 'name', '/api/marketlimit/v1/getallmarketlimit?tenantId='+this.commonService.getFromStorage('tenantId'), '', 'Market Limit', '');
                this.grid.setSaveUrl(environment.base_url + '/api/marketlimit/v1/createmarketlimit');
                this.grid.setUpdateUrl(environment.base_url + '/api/marketlimit/v1/updatemarketlimit?tenantId=' +this.commonService.getFromStorage('tenantId'));
                this.grid.hrefUrl = environment.base_url + '/api/marketlimit/v1/getmarketlimitbyid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
                this.grid.importUrl = environment.base_url + '/api/marketlimit/v1/importmarketlimit';
                this.grid.iconName = 'profitCenter';
                this.grid.addSection('Market Limit Details', ['name', 'profitcenter', 'tradeType', 'traderName', 'commodity',
                    'openPosition', 'openPositionUom', 'drawdownLimits', 'drawdownLimitsCurrency','description','notification','notificationType','status']);
            });
        let tenantConfig=JSON.parse(this.commonService.getFromStorage("tenantConfig"));
        if(tenantConfig && tenantConfig.roundingFormat){
            this.priceRoundOff=tenantConfig.roundingFormat.priceRounding;
            this.quantityRoundOff= tenantConfig.roundingFormat.quantityRounding;
        }
        else{
            this.priceRoundOff=2;
            this.quantityRoundOff=3;
        }
    }

    public getColumns() {
        let _this=this;
        let openPositionValueFunction = function(value: any) {
            return _this.commonService.formatNumberWithoutComma(value,_this.priceRoundOff);
        }
        let drawDownLimitValueFunction= function(value: any) {
            return _this.commonService.formatNumberWithoutComma(value,_this.priceRoundOff);
        }
        this.columns = new Map();
        this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        this.columns.set('name', new Tcolumn('name', 'Market Limit Name', 'T', 1, false, undefined, true, '', 'B', [Validators.required, Validators.maxLength(100)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
        this.columns.set('profitcenter', new Tcolumn('profitcenter', 'Profit center', 'L', 2, true, this.masterCommonService.getListFromArray(this.onLoadData['profitcenter'], false, true, 'All', 'all'), true, 'All', 'B', [Validators.maxLength(200)]));
        this.columns.set('tradeType', new Tcolumn('tradeType', 'Trade Type', 'L', 3, true, this.masterCommonService.getListFromArray(this.onLoadData['trade_type_ind'], false, true, 'All', 'all'), true, 'All', 'B', [Validators.required, Validators.maxLength(200)]));
        this.columns.set('traderName', new Tcolumn('traderName', 'Trader Name', 'L', 4, true, this.masterCommonService.getListFromUrl('/api/trade/v1/getuser?tenantId=' + this.commonService.getFromStorage('tenantId') + '&userType=Trader', 'value', 'value', false, true, '', 'All', 'all'), true, 'All', 'B', [Validators.required, Validators.maxLength(200)]));
        this.columns.set('commodity', new Tcolumn('commodity', 'Commodity', 'L', 5, true, this.masterCommonService.getListFromArray(this.onLoadData['commodity'], false, true, 'All', 'all'), true, 'All', 'B', [Validators.maxLength(200)]));
        this.columns.set('openPosition', new Tcolumn('openPosition', 'Open Position', 'N', 6, true, undefined, true,  new defaultValue('', undefined, undefined, undefined, false, false, false, openPositionValueFunction), 'B', [Validators.min(0), ctrmValidators.decimalLengthValidator(10, 4)]));
        this.columns.set('openPositionUom', new Tcolumn('openPositionUom', 'Open Position UoM', 'LB', 7, true, this.masterCommonService.getListFromArray(this.onLoadData['unitofmeasurement']), true, 'MT', 'B', [Validators.min(0), ctrmValidators.decimalLengthValidator(10, 4)]));
        this.columns.set('drawdownLimits', new Tcolumn('drawdownLimits', 'Drawdown Limits', 'N', 8, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false, drawDownLimitValueFunction), 'B', [Validators.min(0), ctrmValidators.decimalLengthValidator(10, 8)]));
        this.columns.set('drawdownLimitsCurrency', new Tcolumn('drawdownLimitsCurrency', 'Drawdown Limits Currency', 'LB', 9, true, this.commonService.getCurrencyList(), true, 'USD', 'B', [Validators.min(0), ctrmValidators.decimalLengthValidator(10, 8)]));
        this.columns.set('description', new Tcolumn('description', 'Description', 'TA', 11, true, undefined, true, '', 'B', [Validators.maxLength(200)]));
        this.columns.set('notification', new Tcolumn('notification', 'Notification', 'ML', 12, true, this.getNotification(), true, '', 'B', [Validators.maxLength(200)]));
        this.columns.set('notificationType', new Tcolumn('notificationType', 'Notification Type', 'L', 13, true, this.masterCommonService.getListFromArray(this.onLoadData['notificaiton_type_ind']), true, 'warning', 'B', [Validators.maxLength(200)]));
        this.columns.set('status', new Tcolumn('status', 'Status', 'B', 14, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
    }

    getNotification() {
        let notificationList = [];
        notificationList.push(new KeyValue('Email', 'email'));
        notificationList.push(new KeyValue('In App', 'inApp'));
        return notificationList;
    }

}
