import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

async function main(){
  const serveTime =await fetch('/api-iam/api/ping');
  sessionStorage.setItem('timeGap', '' + (new Date(await serveTime.text() ).getTime()-new Date().getTime()) )
}

main()
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
